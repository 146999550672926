export const BLOCKCHAIN__SET_LOADING = '[BLOCKCHAIN] SET_LOADING';
export const BLOCKCHAIN__SET_ACCOUNT = '[BLOCKCHAIN] SET_ACCOUNT';
export const BLOCKCHAIN__FETCH = '[BLOCKCHAIN] FETCH';
export const BLOCKCHAIN__SET_WEB3MODAL = '[BLOCKCHAIN] SET_WEB3MODAL';
export const BLOCKCHAIN__SET_PROVIDER = '[BLOCKCHAIN] SET_PROVIDER';
export const BLOCKCHAIN__DISCONNECT = '[BLOCKCHAIN] DISCONNECT';
export const BLOCKCHAIN__MINT = '[BLOCKCHAIN] MINT';
export const BLOCKCHAIN__UPDATE_ACCOUNT_ASYNC = '[BLOCKCHAIN] UPDATE_ACCOUNT_ASYNC';
export const BLOCKCHAIN__SET_IS_CONNECTED = '[BLOCKCHAIN] SET_IS_CONNECTED';
export const BLOCKCHAIN__SET_CONTRACT = '[BLOCKCHAIN] SET_CONTRACT';

export const APP__SET_WIDTH = '[APP] SET_WIDTH';
export const APP__SET_NAV_OPEN = '[APP] SET_NAV_OPEN';

export const CONTRACT_SET_LOADING = '[CONTRACT] SET_LOADING';
export const CONTRACT__FETCH = '[CONTRACT] FETCH';
export const CONTRACT__SET_DATA = '[CONTRACT] SET_DATA';
export const CONTRACT__SET_REFERRAL_CODE = '[CONTRACT] SET_REFERRAL_CODE';
