import React from 'react';
import {
  BannerBigText,
  BannerContainer,
  BannerContainerOverlay,
  BannerFooter,
  BannerLogo,
  BannerLogoContainer,
  BannerMediumText,
  BannerSmallText,
  BannerTextContainer,
} from './styles';
import { Logos } from './constants';
import { useAppSelector } from '../../../store';
import { appWidthSelector } from '../../../store/selectors/app-selectors';
import MintModal from '../mint-modal';

const Banner = (): JSX.Element => {
  const width = useAppSelector(appWidthSelector);

  // const handleOpenDiscord = () => {
  //   window.open('https://discord.gg/3t9C3pbBeD');
  // };

  const handleOpenTwitter = () => {
    window.open('https://twitter.com/WagmiApes');
  };

  if (width > 650) {
    return (
      <BannerContainer>
        <MintModal />
        <BannerFooter>
          <BannerLogoContainer>
            <BannerLogo src={Logos[1].logoUrl} onClick={() => handleOpenTwitter()} style={{ width: '50px', height: '45px' }} />
          </BannerLogoContainer>
        </BannerFooter>
      </BannerContainer>
    );
  }
  return (
    <BannerContainer>
      <MintModal />
    </BannerContainer>
  );
};

export default Banner;
