import React from 'react';
import { CheckImg } from '../roadmap/styles';
import { AboutApe, AboutContainer, AboutDescription, AboutTitle, AboutTitleText, AboutWrapper, AboutWrapperText } from './styled';
import checkred from './checkred.png';
import ape from './ape.png';

const About = (): JSX.Element => {
  return (
    <AboutContainer>
      <AboutWrapper>
        <AboutWrapperText>
          <AboutTitle>
            <CheckImg src={checkred} />
            <AboutTitleText>ABOUT</AboutTitleText>
          </AboutTitle>
          <AboutDescription>
            The WAGMI APES started in 2023, as a result of experiments from different NFT Creators. As time passed, the Apes began to recognize the harm humans were causing them.
            In an epic movement, Chief Ape led the Apes to freedom. United, The WAGMI APES grew and thrived and continues to operate around the world. Join The Great WAGMI APES
            Today!
          </AboutDescription>
          <AboutDescription>
            Our variety of traits is unprecedented - 45+ types of clothes, 25+ types of hair styles, hats, faces, and more. 1,515 randomly generated digital collectibles of various
            rarity living on the Ethereum blockchain as ERC-721 tokens.
          </AboutDescription>
        </AboutWrapperText>
        <AboutApe src={ape} />
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
