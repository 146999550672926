import React from 'react';
import {
  OverViewContainer,
  OverViewDescriptionContainer,
  OverViewDescriptionTitle,
  OverViewDescriptionText,
  OverViewDescriptionImg,
  OverViewInformationContainer,
  OverViewDescriptionImg1,
  OverViewInformationContainerReverse,
  OverViewDescriptionTextSecond,
  OverViewGrid,
  OverViewImg,
} from './styles';
import { OverviewDescription, OverViewImages } from './constants';
import { useAppSelector } from '../../../store';
import { appWidthSelector } from '../../../store/selectors/app-selectors';

const OverView = (): JSX.Element => {
  const width = useAppSelector(appWidthSelector);

  return (
    <OverViewContainer>
      <OverViewDescriptionTitle>GALLERY</OverViewDescriptionTitle>
      <OverViewGrid>
        {OverViewImages.map(e => (
          <OverViewImg src={e.link} key={e.link} />
        ))}
      </OverViewGrid>
    </OverViewContainer>
  );
};

export default OverView;
