import { Element } from 'react-scroll';
import Header from '../../components/header';
import { useAppSelector } from '../../store';
import { blockchainAccountSelector, blockchainLoadingSelector } from '../../store/selectors/blockchain-selectors';
import { contractLoadingSelector } from '../../store/selectors/contract-selectors';
import { HomeContainer, WrapBackground } from './styles';
import Banner from '../components/banner';
import Overview from '../components/overview';
import RoadMap from '../components/roadmap';
import MintDistribution from '../components/mintdistribution';
import Team from '../components/team';
import Faq from '../components/faq';
import Footer from '../components/footer';
import About from '../components/about';
import initMerkleTree from '../../utils/merkle';
import { whitelistAddresses } from '../../constants/whitelist-addresses';
import ISalePhase from '../../types/ISalePhase';

const Home = (): JSX.Element => {
  const blockchainLoading = useAppSelector(blockchainLoadingSelector);
  const contractLoading = useAppSelector(contractLoadingSelector);

  return (
    <HomeContainer>
      {/* <AppLoaderOverlay isLoading={blockchainLoading || contractLoading} /> */}
      <Header />
      <WrapBackground>
        <Element name="banner">
          <Banner />
        </Element>
        <Element name="overview">
          <Overview />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <MintDistribution />
        <Element name="team">
          <Team />
        </Element>
        <Element name="faq">
          <Faq />
        </Element>
      </WrapBackground>
      <Footer />
    </HomeContainer>
  );
};

export default Home;
