export interface FaqInterface {
  question: string;
  ans: string;
}

export const FaqObj: FaqInterface[] = [
  {
    question: 'When is the mint date?',
    ans: `Mint date will be on 14th of April , 9 PM UTC . Let's smash it and show them the power of the Apes!`,
  },
  {
    question: 'What is the price?',
    ans: 'The first one is free. Then each additional one costs 0.015 for WL and 0.025 for Public.',
  },
  {
    question: 'Who is behind Wagmi Apes?',
    ans: 'We are a team of talented people in the web3 space which have over 7 years in experience.',
  },
];
