import { Element } from 'react-scroll';
import styled from '../../theme';
import background from './something.png';

export const HomeContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const WrapBackground = styled.div`
  background-image: url(${background});
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-color: #060606;
`;

export const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HomeItemCentered = styled(Element)`
  min-height: 700px;
  display: flex;
  flex-direction: column;
`;
