import React from 'react';
import {
  MintDescriptionText,
  MintDistributionCheck,
  MintDistributionCheckContainer,
  MintDistributionCheckTitle,
  MintDistributionContainer,
  MintDistributionDetailsContainer,
  MintDistributionDetalisText,
  MintDistributionInformationContainer,
  MintDistributionTitle,
} from './styles';
import { MintDistributionFirst, MintDistributionSecond, MintDistributionTexts } from './constants';
import check from './check.png';
import checkred from './checkred.png';

const MintDistribution = (): JSX.Element => {
  return (
    <MintDistributionContainer>
      <MintDistributionInformationContainer>
        {MintDistributionFirst.map(e => (
          <MintDistributionDetailsContainer key={e.percentage}>
            <MintDistributionCheckContainer>
              <MintDistributionCheck src={checkred} />
              <MintDistributionCheckTitle>{e.percentage}</MintDistributionCheckTitle>
            </MintDistributionCheckContainer>
            <MintDistributionDetalisText>{e.description}</MintDistributionDetalisText>
          </MintDistributionDetailsContainer>
        ))}
      </MintDistributionInformationContainer>
    </MintDistributionContainer>
  );
};

export default MintDistribution;
