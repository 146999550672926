import React from 'react';
import {
  FooterLine,
  FooterContainer,
  FooterLogoTextSection,
  FooterLeftSection,
  FooterLogoDescription,
  FooterLogoTitle,
  FooterLogo,
  FooterWrapper,
  FooterCopyRight,
  FooterBegging,
} from './styles';
import logo from './logo.png';

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterLine />
      <FooterWrapper>
        <FooterLeftSection>
          <FooterLogoTextSection>
            <FooterLogoTitle>WAGMI APES</FooterLogoTitle>
          </FooterLogoTextSection>
          <FooterCopyRight>2023 © Designed by the Apes. All rights reserved Wagmi Apes</FooterCopyRight>
          <FooterBegging>WAGMI APES STRONG!</FooterBegging>
        </FooterLeftSection>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
