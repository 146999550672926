import styled from 'styled-components';

export const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #060606; */
  flex-direction: column;
  padding: 100px 20px 0px 10px;
`;

export const AboutWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
  flex-direction: row;
  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    flex-direction: center;
  }
`;

export const AboutWrapperText = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
export const AboutTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
`;

export const AboutTitleText = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.medium};
`;

export const AboutCheck = styled.img``;

export const AboutDescription = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSize.mSmall};
  max-width: 400px;
  font-family: ${props => props.theme.fonts.interReg};
`;

export const AboutApe = styled.img``;
