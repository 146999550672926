import paul from '../overview/assets/p1.png';
import marius from '../overview/assets/p2.png';
import vlad from '../overview/assets/p3.png';
import daniel from './assets/daniel_2.png';
import madalin from './assets/madalin.png';
import dominika from './assets/dominika.png';

export interface TeamInterface {
  imageUrl: string;
  name: string;
  role: string;
}

export const TeamObj: TeamInterface[] = [
  {
    imageUrl: vlad,
    name: 'Wagmi Web Designer',
    role: 'UI & UX Designer',
  },
  {
    imageUrl: paul,
    name: 'Wagmi Dev',
    role: 'Full Stack Dev',
  },
  {
    imageUrl: marius,
    name: 'Wagmi Block Dev',
    role: 'Blockchain Dev',
  },
  {
    imageUrl: 'https://valhalla-nft-production.s3.amazonaws.com/4751.png',
    name: 'Wagmi Painter',
    role: 'Art Director',
  },
  {
    imageUrl: 'https://live---metadata-5covpqijaa-uc.a.run.app/images/5748',
    name: 'Wagmi Master',
    role: 'Marketing Master',
  },
  {
    imageUrl: daniel,
    name: 'Wagmi Bodyguard',
    role: 'Discord & Security',
  },
];
