import { ethers } from 'ethers';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { blockchainMintNftAsyncAction, fecthBlockchainDataActionAsync } from '../../../store/actions/blockchain-actions';
import { blockchainAccountSelector, blockchainIsConnectedSelector } from '../../../store/selectors/blockchain-selectors';
import { contractDataSelector } from '../../../store/selectors/contract-selectors';
import ISalePhase from '../../../types/ISalePhase';
import initMerkleTree from '../../../utils/merkle';
import { MintModalButton, MintModalContainer, MintModalData, MintModalInformation, MintModalTitle, MMCButton, MMCounter, MMCValueContainer } from './styles';

const MintModal = (): JSX.Element => {
  const isConnected = useAppSelector(blockchainIsConnectedSelector);
  const account = useAppSelector(blockchainAccountSelector);
  const contractData = useAppSelector(contractDataSelector);
  const [value, setValue] = useState(1);
  const dispatch = useAppDispatch();

  const maxMintPerTx = useMemo(() => {
    return contractData.publicMintPerTx - contractData.mintedNftsByUser;
  }, [contractData]);
  const buildTitle = (): string => {
    if (!isConnected) {
      return 'Private sale is live now!';
    }
    if (contractData.paused) {
      return 'Sale is closed!';
    }

    return 'Sale is live now!';
  };
  const buildPrice = (): string => {
    let proofs;
    let price;
    if (isConnected) {
      proofs = initMerkleTree(account, ISalePhase.WHITELIST);
    }
    if (!isConnected) {
      return '0.025 ETH';
    }
    if (proofs.length !== 0 && contractData.merkleState) {
      if (contractData.mintedWhitelistNftsByUser === 0 && value === 1) return 'Free !';
      if (contractData.mintedWhitelistNftsByUser === 0 && value !== 1) price = Number(ethers.utils.formatEther(contractData.whitelistPrice)) * (value - 1);
      else price = Number(ethers.utils.formatEther(contractData.whitelistPrice)) * value;

      price = price.toString();
      price = price.substring(0, 6);
      return `${price} ETH`;
    }
    if (contractData.mintedNftsByUser === 0 && proofs.length === 0) {
      price = Number(ethers.utils.formatEther(contractData.price)) * (value - 1);
      if (contractData.mintedNftsByUser === 0 && value === 1) return 'Free !';
      if (contractData.mintedNftsByUser === 0 && value !== 1) price = Number(ethers.utils.formatEther(contractData.price)) * (value - 1);
    } else price = Number(ethers.utils.formatEther(contractData.price)) * value;

    price = price.toString();
    price = price.substring(0, 6);
    return `${price} ETH`;
  };
  const buildSupplyLeft = (): string => {
    if (!isConnected) {
      return '0/1515';
    }
    return `${contractData.currentSupply}/${contractData.maxSupply}`;
  };
  const handleIncrement = (): void => {
    if (value + 1 > maxMintPerTx) {
      return;
    }
    setValue(prev => prev + 1);
  };
  const handleDecrement = (): void => {
    if (value === 1) {
      return;
    }
    setValue(prev => prev - 1);
  };
  const buildButton = (): JSX.Element => {
    if (isConnected) {
      return (
        <MintModalButton
          isDisabled={contractData.paused || contractData.maxSupply === contractData.currentSupply}
          disabled={contractData.paused || contractData.currentSupply === contractData.maxSupply}
          onClick={() => dispatch(blockchainMintNftAsyncAction({ amount: value }))}
        >
          Mint now
        </MintModalButton>
      );
    }
    return <MintModalButton onClick={() => dispatch(fecthBlockchainDataActionAsync())}>Connect</MintModalButton>;
  };
  const buildInfoSection = (): JSX.Element => {
    if (isConnected) {
      return (
        <>
          <MintModalTitle>{buildTitle()}</MintModalTitle>
          <MintModalInformation>Supply minted</MintModalInformation>
          <MintModalData>{buildSupplyLeft()}</MintModalData>
          <MintModalInformation>Price</MintModalInformation>
          <MintModalData>{buildPrice()}</MintModalData>
          <MMCounter>
            <MMCButton onClick={handleDecrement}>-</MMCButton>
            <MMCValueContainer>{value}</MMCValueContainer>
            <MMCButton onClick={handleIncrement}>+</MMCButton>
          </MMCounter>
        </>
      );
    }
    return <MintModalTitle>Connect your wallet</MintModalTitle>;
  };
  return (
    <MintModalContainer>
      {buildInfoSection()}

      {buildButton()}
    </MintModalContainer>
  );
};

export default MintModal;
